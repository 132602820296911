// Sidebar.js
import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Logo from "./../imgs/icons.svg";
import "./Nav.css";

const Sidebar = () => {
  return (
    <div className="bg-white" id="sidebar-wrapper">
      <div className="sidebar-heading text-center">
        <img src={Logo} alt="" />
      </div>
      <div className="list-group list-group-flush mt-4">
        <Nav className="flex-column">
          <NavLink
            exact
            to="/components/hero"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Hero Section
            </a>
          </NavLink>
          <NavLink
            to="/components/projects"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Our Projects
            </a>
          </NavLink>
          <NavLink
            to="/components/stats"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Our Stats
            </a>
          </NavLink>
          <NavLink
            to="/components/partners"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Our Partners
            </a>
          </NavLink>
          <NavLink
            to="/components/services"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Our Services
            </a>
          </NavLink>
          <NavLink
            to="/components/testimonial"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Testimonial
            </a>
          </NavLink>
          <NavLink
            to="/components/footer"
            className="nav-link"
            activeClassName="active"
          >
            <a
              className="nav-link list-group-item-action border-0 p-3 ps-4"
              href="#!"
            >
              <i className="bi bi-grid me-3"></i> Footer Section
            </a>
          </NavLink>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
