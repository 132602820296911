import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { partnersApi } from "./../API/api";

const Create_partners = () => {
  const [partnersName, setpartnersName] = useState("");
  const [partnersLogo, setpartnersLogo] = useState(null);
  const history = useHistory();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setpartnersLogo(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("partner_name", partnersName);
    formData.append("partner_logo", partnersLogo);

    fetch(partnersApi, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Create successful:", data);
        history.push("/components/partners");
      })
      .catch((error) => {
        console.error("Error creating data:", error.message);
      });
  };

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <h3 className="fw-bold">Create partners</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="partnersName">
                <Form.Label>Partner Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Partner Name"
                  value={partnersName}
                  onChange={(e) => setpartnersName(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="partnersLogo">
                <Form.Label>Icon</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  required
                />
              </Form.Group>

              <Button variant="warning" type="submit">
                Create
              </Button>
              <Link
                to="/components/partners"
                className="btn btn-secondary ms-2"
              >
                Cancel
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Create_partners;
