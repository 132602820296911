// Stats.js
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Modal, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { statsApi } from "./../API/api";

const Stats = () => {
  const [empdata, empdatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const history = useHistory();

  const LoadEdit = (stat_id) => {
    history.push("/updates/edit_stats/" + stat_id);
  };

  const handleDelete = (stat_id) => {
    setDeleteItemId(stat_id);
  };

  const confirmDelete = () => {
    fetch(`${statsApi}/${deleteItemId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Delete successful:", data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error.message);
      })
      .finally(() => {
        setDeleteItemId(null);
      });
  };

  const handleClose = () => {
    setDeleteItemId(null);
  };

  const fetchData = () => {
    fetch(statsApi)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        console.log("API Response:", resp);

        const data = Array.isArray(resp) ? resp : resp.data || [];

        empdatachange(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <div className="d-flex">
              <h3 className="fw-bold">Stats</h3>
              <a href="#!" className="btn btn-warning ms-auto text-white">
                <Nav.Link as={Link} to="/creates/create_stats">
                  Tambah data
                </Nav.Link>
              </a>
            </div>

            <div className="row pt-4">
              {loading ? (
                <p>Loading...</p>
              ) : empdata.length > 0 ? (
                empdata.map((item) => (
                  <div
                    className="col-lg-12 shadow-sm p-3 mb-3 bg-body-tertiary rounded"
                    key={item.stat_id}
                  >
                    <div className="row">
                      <div className="col-md-3 ps-4">
                        <p>stat_title</p>
                        <h5 className="ps-2">{item.stat_title}</h5>
                      </div>
                      <div className="col-md-3 ps-4">
                        <p>stat_count</p>
                        <h5 className="ps-2">{item.stat_count}</h5>
                      </div>
                      <div className="col-md-3 ps-4">
                        <p>stat_icon</p>
                        <h5 className="ps-2">
                          {item.icon_url && (
                            <img
                              src={item.icon_url}
                              alt={item.stat_title}
                              height="30rem"
                            />
                          )}
                        </h5>
                      </div>
                      <div className="col-md-3 ps-4">
                        <p>stat_action</p>
                        <div className="d-flex ps-2">
                          <button
                            onClick={() => {
                              LoadEdit(item.stat_id);
                            }}
                            className="btn btn-warning"
                          >
                            <i className="bi bi-pencil-square"></i> Edit
                          </button>
                          <button
                            onClick={() => {
                              handleDelete(item.stat_id);
                            }}
                            className="btn btn-danger ms-2"
                          >
                            <i className="bi bi-trash"></i> Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No data available</p>
              )}
            </div>
          </div>
        </div>

        <Modal show={deleteItemId !== null} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Apakah Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={confirmDelete}>
              Ya, hapus
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Tidak
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default Stats;
