// Edit_testimonial.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import authToken from "./../token/Token";
import { testimonialsApi } from "./../API/api";

const Edit_testimonial = ({ match }) => {
  const [clientName, setClientName] = useState("");
  const [clientRole, setClientRole] = useState("");
  const [message, setMessage] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [initialProfileImage, setInitialProfileImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchTestimonialData = async () => {
      try {
        const response = await axios.get(
          `${testimonialsApi}/${match.params.client_id}`
        );

        const testimonialData = response.data.data;

        setClientName(testimonialData.client_name);
        setClientRole(testimonialData.client_role);
        setMessage(testimonialData.message);
        setInitialProfileImage(testimonialData.profile_image);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchTestimonialData();
  }, [match.params.client_id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/testimonial");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("client_name", clientName);
    formData.append("client_role", clientRole);
    formData.append("message", message);
    formData.append("_method", "PUT");

    if (profileImage) {
      formData.append("profile_image", profileImage);
    }

    try {
      await axios.post(
        `${testimonialsApi}/${match.params.client_id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="clientName">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="clientRole">
                  <Form.Label>Client Role</Form.Label>
                  <Form.Control
                    type="text"
                    value={clientRole}
                    onChange={(e) => setClientRole(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Testimonial Message</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={message}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setMessage(data);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="profileImage">
                  <Form.Label>Profile Image</Form.Label>
                  <Form.Control type="file" onChange={handleImageChange} />
                  <img
                    src={initialProfileImage}
                    alt="Initial Profile"
                    width="100"
                    className="my-3"
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_testimonial;
