// Testimonial.js
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Modal, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { testimonialsApi } from "./../API/api";

const Testimonial = () => {
  const [empdata, empdatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const history = useHistory();

  const LoadEdit = (client_id) => {
    history.push("/updates/edit_testimonial/" + client_id);
  };

  const handleDelete = (client_id) => {
    setDeleteItemId(client_id);
  };

  const confirmDelete = () => {
    fetch(`${testimonialsApi}/${deleteItemId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Delete successful:", data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error.message);
      })
      .finally(() => {
        setDeleteItemId(null);
      });
  };

  const handleClose = () => {
    setDeleteItemId(null);
  };

  const fetchData = () => {
    fetch(testimonialsApi)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        console.log("API Response:", resp);

        const data = Array.isArray(resp) ? resp : resp.data || [];

        empdatachange(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <div className="d-flex">
              <h3 className="fw-bold">Testimonial</h3>
              <a href="#!" className="btn btn-warning ms-auto text-white">
                <Nav.Link as={Link} to="/creates/create_testimonial">
                  Tambah data
                </Nav.Link>
              </a>
            </div>

            <div className="row row-cols-1 row-cols-md-3 g-4 pt-4">
              {loading ? (
                <p>Loading...</p>
              ) : empdata.length > 0 ? (
                empdata.map((item) => (
                  <div className="col" key={item.client_id}>
                    <div className="card">
                      {item.profile_image && (
                        <img
                          src={item.profile_image}
                          alt={item.client_name}
                          className="p-3 pb-0"
                          width="100%"
                        />
                      )}
                      <div className="card-body">
                        <h5 className="card-title text-center">
                          {item.client_name}
                        </h5>
                        <p className="text-center">{item.client_role}</p>
                        <div className="d-flex mt-3">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#exampleModal1-${item.client_id}`}
                          >
                            <i className="bi bi-bookmark-fill"></i>
                          </button>

                          <div
                            className="modal fade"
                            id={`exampleModal1-${item.client_id}`}
                            tabIndex="-1"
                            aria-labelledby={`exampleModalLabel-${item.client_id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1
                                    className="modal-title fs-5"
                                    id={`exampleModalLabel-${item.client_id}`}
                                  >
                                    Detail Message
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <article
                                    dangerouslySetInnerHTML={{
                                      __html: item.message,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="ms-auto">
                            <button
                              onClick={() => {
                                LoadEdit(item.client_id);
                              }}
                              className="btn btn-warning ms-2"
                            >
                              <i className="bi bi-pencil-square"></i>
                            </button>
                            <button
                              onClick={() => {
                                handleDelete(item.client_id);
                              }}
                              className="btn btn-danger ms-2"
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No data available</p>
              )}
            </div>

            <Modal show={deleteItemId !== null} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Apakah Anda yakin ingin menghapus data ini?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={confirmDelete}>
                  Ya, hapus
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Tidak
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
