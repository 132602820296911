import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import authToken from "./../token/Token";
import { heroApi } from "./../API/api";

const Edit_hero = ({ match }) => {
  const [titleName, setTitleName] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${heroApi}/${match.params.id}`);

        const heroData = response.data.data;

        setTitleName(heroData.title);
        setContent(heroData.content);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchHeroData();
  }, [match.params.id]);

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/hero");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", titleName);
    formData.append("content", content);
    formData.append("_method", "PUT");

    try {
      await axios.post(`${heroApi}/${match.params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group controlId="TitleName">
                  <Form.Label>
                    <h4>Hero Title</h4>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={titleName}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTitleName(data);
                    }}
                  />
                </Form.Group>

                <Button
                  variant="warning"
                  onClick={handleSubmit}
                  className="mt-3"
                >
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_hero;
