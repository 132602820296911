// Create_projects.js
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Form, Button, Image, Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import authToken from "./../token/Token";
import { projectsApi } from "./../API/api";

const Create_projects = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [project_url, setProject_url] = useState("");
  const [project_type, setProject_type] = useState("");
  const [image_url, setImage_url] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  const handleImage_urlUpload = (e) => {
    const files = e.target.files;
    setImage_url(files);

    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (event) => {
        previews.push(event.target.result);
        if (previews.length === files.length) {
          setImagePreviews(previews);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  const handleProjectUrlChange = (event, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("project_url", project_url);
    formData.append("project_type", project_type);

    for (let i = 0; i < image_url.length; i++) {
      formData.append("image_url[]", image_url[i]);
    }

    try {
      const response = await fetch(`${projectsApi}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Upload successful:", data);

      setShowModal(true);
    } catch (error) {
      console.error("Error uploading data:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/projects");
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col">
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formTitle" className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formproject_url" className="mb-3">
                <Form.Label>project_url</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter project_url"
                  value={project_url}
                  onChange={(e) => setProject_url(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formproject_type" className="mb-3">
                <Form.Label>project_type</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter project_type"
                  value={project_type}
                  onChange={(e) => setProject_type(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formDescription" className="mb-3">
                <Form.Label>Description</Form.Label>
                <CKEditor
                  editor={ClassicEditor}
                  data={description}
                  onChange={handleProjectUrlChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formImage_url" className="mb-3">
                <Form.Label>Image_url</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleImage_urlUpload}
                  required
                />
                {/* Display image previews */}
                {imagePreviews.map((preview, index) => (
                  <Image
                    key={index}
                    src={preview}
                    alt={`Preview ${index}`}
                    fluid
                    rounded
                    className="mt-2"
                  />
                ))}
              </Form.Group>
              <Button variant="warning" type="submit">
                Submit
              </Button>
              <Button className="ms-2" variant="danger">
                <Nav.Link as={Link} to="/components/projects">
                  Close
                </Nav.Link>
              </Button>
            </Form>

            {/* Modal for success notification */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Berhasil</Modal.Title>
              </Modal.Header>
              <Modal.Body>Data project berhasil ditambahkan!</Modal.Body>
              <Modal.Footer>
                <Button variant="warning" onClick={handleCloseModal}>
                  OK
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Create_projects;
