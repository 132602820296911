// Content.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import authToken from "./../token/Token";
import { footerApi } from "./../API/api";

const Content = ({ match }) => {
  const [logoTitle, setLogoTitle] = useState("");
  const [address, setAddress] = useState("");
  const [logoUrl, setLogoUrl] = useState(null);
  const [initialLogoUrl, setInitialLogoUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await axios.get(`${footerApi}/${match.params.id}`);

        const contentData = response.data.data;

        setLogoTitle(contentData.logo_title);
        setAddress(contentData.address);
        setInitialLogoUrl(contentData.logo_url);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchContentData();
  }, [match.params.id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setLogoUrl(file);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/footer");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("logo_title", logoTitle);
    formData.append("address", address);
    formData.append("_method", "PUT");

    if (logoUrl) {
      formData.append("logo_url", logoUrl);
    }

    try {
      await axios.post(`${footerApi}/${match.params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="logoTitle">
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={logoTitle}
                    onChange={(e) => setLogoTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Testimonial address</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={address}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setAddress(data);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="logoUrl">
                  <Form.Label>Profile Image</Form.Label>
                  <Form.Control type="file" onChange={handleImageChange} />
                  <img
                    src={initialLogoUrl}
                    alt="Initial Profile"
                    width="100"
                    className="my-3 bg-warning"
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
