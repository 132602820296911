// Partners.js
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Modal, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { partnersApi } from "./../API/api";

const Partners = () => {
  const [empdata, empdatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const history = useHistory();

  const LoadEdit = (partner_id) => {
    history.push("/updates/edit_partners/" + partner_id);
  };

  const handleDelete = (partner_id) => {
    setDeleteItemId(partner_id);
  };

  const confirmDelete = () => {
    fetch(`${partnersApi}/${deleteItemId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Delete successful:", data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error.message);
      })
      .finally(() => {
        setDeleteItemId(null);
      });
  };

  const handleClose = () => {
    setDeleteItemId(null);
  };

  const fetchData = () => {
    fetch(partnersApi)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        console.log("API Response:", resp);

        const data = Array.isArray(resp) ? resp : resp.data || [];

        empdatachange(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <div className="d-flex">
              <h3 className="fw-bold">Partners</h3>
              <a href="#!" className="btn btn-warning ms-auto text-white">
                <Nav.Link as={Link} to="/creates/create_partners">
                  Tambah data
                </Nav.Link>
              </a>
            </div>

            <div className="row row-cols-1 row-cols-md-3 g-4 pt-4">
              {loading ? (
                <p>Loading...</p>
              ) : empdata.length > 0 ? (
                empdata.map((item) => (
                  <div className="col" key={item.partner_id}>
                    <div className="card">
                      {item.partner_logo && (
                        <img
                          src={item.partner_logo}
                          alt={item.partner_name}
                          className="p-3 pb-0"
                          width="60%"
                        />
                      )}
                      <div className="card-body">
                        <h5 className="card-title">{item.partner_name}</h5>
                        <div className="d-flex mt-3">
                          <button
                            onClick={() => {
                              LoadEdit(item.partner_id);
                            }}
                            className="btn btn-warning"
                          >
                            <i className="bi bi-pencil-square"></i> Edit
                          </button>
                          <button
                            onClick={() => {
                              handleDelete(item.partner_id);
                            }}
                            className="btn btn-danger ms-2"
                          >
                            <i className="bi bi-trash"></i> Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No data available</p>
              )}
            </div>

            <Modal show={deleteItemId !== null} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Apakah Anda yakin ingin menghapus data ini?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={confirmDelete}>
                  Ya, hapus
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                  Tidak
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
