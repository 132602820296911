// Footer.js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getFooterData,
  getCollaborateData,
  getContactusData,
  getCompanyData,
} from "./../API/api";

const Footer = () => {
  const [empdata, empdatachange] = useState([]);
  const [colabdata, colabdatachange] = useState([]);
  const [contactusdata, contactusdatachange] = useState([]);
  const [companydata, companydatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const LoadContent = (id) => {
    history.push("/footer/content/" + id);
  };

  const LoadCollaborate = (id) => {
    history.push("/footer/collaborate/" + id);
  };

  const LoadContact = (id) => {
    history.push("/footer/Contact/" + id);
  };

  const LoadCompany = (id) => {
    history.push("/footer/Companys/" + id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFooterData();
        empdatachange(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCollaborateData();
        colabdatachange(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getContactusData();
        contactusdatachange(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getCompanyData();
        companydatachange(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          {loading ? (
            <p>Loading...</p>
          ) : colabdata.length > 0 ? (
            colabdata.map((items) => (
              <div className="col-lg-12 mb-4 bg-white">
                <div className="d-flex">
                  <h3 className="fw-bold">Section</h3>
                </div>
                <div className="row pt-4" key={items.id}>
                  <div className="col-md-5 mb-3">
                    <h6>Title</h6>
                    <h4 className="fw-normal">{items.title}</h4>
                  </div>
                  <div className="col-md-5 mb-3">
                    <h6>Link</h6>
                    <h4 className="fw-normal">{items.url}</h4>
                  </div>
                  <div className="col-md-2 mb-3">
                    <h6>Action</h6>
                    <button
                      onClick={() => {
                        LoadCollaborate(items.id);
                      }}
                      className="btn btn-warning"
                    >
                      <i className="bi bi-pencil-square me-2"></i> Edit
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}

          {loading ? (
            <p>Loading...</p>
          ) : empdata.length > 0 ? (
            empdata.map((item) => (
              <div className="col-lg-12 mb-4 bg-white">
                <h3 className="fw-bold mb-4">Content</h3>
                <img
                  src={item.logo_url}
                  alt={item.logo_title}
                  width="30%"
                  className="mb-3 bg-warning"
                />
                <div className="row justify-content-between">
                  <div className="col-md-5 mb-3">
                    <h6>{item.logo_title}</h6>
                  </div>
                  <div className="col-md-5 mb-3">
                    <article
                      dangerouslySetInnerHTML={{
                        __html: item.address,
                      }}
                    />
                  </div>
                  <div className="col-md-2 mb-3">
                    <button
                      onClick={() => {
                        LoadContent(item.id);
                      }}
                      className="btn btn-warning"
                    >
                      <i className="bi bi-pencil-square me-2"></i> Edit
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No data available</p>
          )}

          <div className="col-lg-12 mb-4 bg-white">
            <h3 className="fw-bold mb-4">Contact</h3>
            {loading ? (
              <p>Loading...</p>
            ) : contactusdata.length > 0 ? (
              contactusdata.map((item) => (
                <div className="row justify-content-between">
                  <div className="col-md-5 mb-3">
                    <p>{item.email}</p>
                  </div>
                  <div className="col-md-5 mb-3">
                    <p>{item.phone_num}</p>
                  </div>
                  <div className="col-md-2 mb-3">
                    <button
                      onClick={() => {
                        LoadContact(item.id);
                      }}
                      className="btn btn-warning"
                    >
                      <i className="bi bi-pencil-square me-2"></i> Edit
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>

          <div className="col-lg-12 mb-4 bg-white">
            <h3 className="fw-bold mb-4">Company</h3>
            {loading ? (
              <p>Loading...</p>
            ) : companydata.length > 0 ? (
              companydata.map((item) => (
                <div className="row justify-content-between">
                  <div className="col-md-5 mb-3">
                    <p>{item.title}</p>
                  </div>
                  <div className="col-md-5 mb-3">
                    <p>{item.url}</p>
                  </div>
                  <div className="col-md-2 mb-3">
                    <button
                      onClick={() => {
                        LoadCompany(item.id);
                      }}
                      className="btn btn-warning"
                    >
                      <i className="bi bi-pencil-square me-2"></i> Edit
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
