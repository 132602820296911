// Profile.js
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import authToken from "./../token/Token";
import { userApi } from "./../API/api";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const history = useHistory();

  const LoadProfile = (id) => {
    history.push("/updates/Edit_profile/" + id);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(userApi, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
          console.log("User Data:", data);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-5">
          {userData && (
            <>
              <div className="col-lg-12 mb-4 bg-white">
                <div className="d-flex">
                  <h3 className="fw-bold">Profile</h3>
                  <button
                    onClick={() => {
                      LoadProfile(userData.id);
                    }}
                    className="btn btn-outline-warning ms-auto"
                  >
                    <i className="bi bi-pencil-square me-2"></i> Setting
                  </button>
                </div>
                <div className="row pt-4">
                  <div className="col-lg-4 mb-3">
                    <p>Username</p>
                    <h6>{userData.name}</h6>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <p>Email</p>
                    <h6>{userData.email}</h6>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Profile;
