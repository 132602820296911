// Hero.js
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { heroApi } from "./../API/api";

const Hero = () => {
  const [empdata, empdatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const LoadTitle = (id) => {
    history.push(`/hero/title_hero/${id}`);
  };

  const LoadContent = (id) => {
    history.push(`/hero/content_hero/${id}`);
  };

  useEffect(() => {
    fetch(heroApi)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        console.log("API Response:", resp);

        const data = Array.isArray(resp) ? resp : resp.data || [];

        empdatachange(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err.message);
        setLoading(false);
      });
  }, []);

  return (
    <section>
      <div className="container wrap-section-1">
        {loading ? (
          <p>Loading...</p>
        ) : empdata.length > 0 ? (
          empdata.map((item) => (
            <div className="row mx-5" key={item.id}>
              <div className="col-lg-12 mb-4 bg-white">
                <div className="d-flex">
                  <h3 className="fw-bold">Headline</h3>
                  <button
                    onClick={() => LoadTitle(item.id)}
                    className="btn btn-outline-warning ms-auto"
                  >
                    <i className="bi bi-pencil-square me-2"></i> Edit
                  </button>
                </div>
                <h2 className="fw-normal pt-4">
                  <article
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                </h2>
              </div>

              <div className="col-lg-12 bg-white">
                <div className="d-flex">
                  <h3 className="fw-bold">Section</h3>
                  <button
                    onClick={() => LoadContent(item.id)}
                    className="btn btn-outline-warning ms-auto"
                  >
                    <i className="bi bi-pencil-square me-2"></i> Edit
                  </button>
                </div>
                <p className="pt-4">
                  <article
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
    </section>
  );
};

export default Hero;
