// Edit_projects.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import authToken from "./../token/Token";
import { projectsApi } from "./../API/api";

const Edit_projects = ({ match }) => {
  const [projectTitle, setProjectTitle] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [projectType, setProjectType] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [initialImageUrl, setInitialImageUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        const response = await axios.get(
          `${projectsApi}/${match.params.project_id}`
        );

        const contentData = response.data.data;

        setProjectTitle(contentData.title);
        setProjectUrl(contentData.project_url);
        setProjectType(contentData.project_type);
        setDescription(contentData.description);
        setInitialImageUrl(contentData.image_url[0].image_url);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchContentData();
  }, [match.params.project_id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageUrl(file);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/projects");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", projectTitle);
    formData.append("project_url", projectUrl);
    formData.append("project_type", projectType);
    formData.append("description", description);
    formData.append("_method", "PUT");

    if (imageUrl) {
      formData.append("image_url", imageUrl);
    }

    try {
      await axios.post(`${projectsApi}/${match.params.project_id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="projectTitle">
                  <Form.Label>Edit Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={projectTitle}
                    onChange={(e) => setProjectTitle(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="projectUrl">
                  <Form.Label>Edit URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={projectUrl}
                    onChange={(e) => setProjectUrl(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="projectType">
                  <Form.Label>Edit Type</Form.Label>
                  <Form.Control
                    type="text"
                    value={projectType}
                    onChange={(e) => setProjectType(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Edit Description</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="imageUrl">
                  <Form.Label>Edit Image</Form.Label>
                  <Form.Control type="file" onChange={handleImageChange} />
                  <img
                    src={initialImageUrl}
                    alt="Initial Profile"
                    width="100"
                    className="my-3"
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_projects;
