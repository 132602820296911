import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { statsApi } from "./../API/api";

const Create_stats = () => {
  const [statTitle, setStatTitle] = useState("");
  const [statCount, setStatCount] = useState("");
  const [statIcon, setStatIcon] = useState(null);
  const history = useHistory();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setStatIcon(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("stat_title", statTitle);
    formData.append("stat_count", statCount);
    formData.append("icon_url", statIcon);

    fetch(statsApi, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Create successful:", data);
        history.push("/components/stats");
      })
      .catch((error) => {
        console.error("Error creating data:", error.message);
      });
  };

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <h3 className="fw-bold">Create Stats</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="statTitle">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  value={statTitle}
                  onChange={(e) => setStatTitle(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="statCount">
                <Form.Label>Count</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter count"
                  value={statCount}
                  onChange={(e) => setStatCount(e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="statIcon">
                <Form.Label>Icon</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  required
                />
              </Form.Group>

              <Button variant="warning" type="submit">
                Create
              </Button>
              <Link to="/components/stats" className="btn btn-secondary ms-2">
                Cancel
              </Link>
            </Form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Create_stats;
