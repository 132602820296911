// Edit_partners.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import authToken from "./../token/Token";
import { partnersApi } from "./../API/api";

const Edit_partners = ({ match }) => {
  const [partnerName, setPartnerName] = useState("");
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [initialPartnerLogo, setInitialPartnerLogo] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        const response = await axios.get(
          `${partnersApi}/${match.params.partner_id}`
        );

        const partnerData = response.data.data;

        setPartnerName(partnerData.partner_name);
        setInitialPartnerLogo(partnerData.partner_logo);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchPartnerData();
  }, [match.params.partner_id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setPartnerLogo(file);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/partners");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("partner_name", partnerName);
    formData.append("_method", "PUT");

    if (partnerLogo) {
      formData.append("partner_logo", partnerLogo);
    }

    try {
      await axios.post(`${partnersApi}/${match.params.partner_id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="partnerName">
                  <Form.Label>Partner Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={partnerName}
                    onChange={(e) => setPartnerName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="partnerLogo">
                  <Form.Label>Edit Icon</Form.Label>
                  <Form.Control type="file" onChange={handleImageChange} />
                  <img
                    src={initialPartnerLogo}
                    alt="Initial Profile"
                    width="100"
                    className="my-3"
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_partners;
