// Project.js
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Nav, Modal, Button } from "react-bootstrap";
import authToken from "./../token/Token";
import { projectsApi } from "./../API/api";

const Projects = () => {
  const [empdata, empdatachange] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const history = useHistory();

  const LoadEdit = (project_id) => {
    history.push("/updates/edit_projects/" + project_id);
  };

  const handleDelete = (project_id) => {
    setDeleteItemId(project_id);
  };

  const confirmDelete = () => {
    fetch(`${projectsApi}/${deleteItemId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((data) => {
        console.log("Delete successful:", data);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error.message);
      })
      .finally(() => {
        setDeleteItemId(null);
      });
  };

  const handleClose = () => {
    setDeleteItemId(null);
  };

  const fetchData = () => {
    fetch(projectsApi)
      .then((res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        return res.json();
      })
      .then((resp) => {
        console.log("API Response:", resp);

        const data = Array.isArray(resp) ? resp : resp.data || [];

        empdatachange(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section className="wrap-section-1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 bg-white">
            <div className="d-flex">
              <h3 className="fw-bold">Projects</h3>
              <button className="btn btn-warning ms-auto text-white">
                <Nav.Link as={Link} to="/creates/create_projects">
                  Tambah data
                </Nav.Link>
              </button>
            </div>

            <div className="row row-cols-1 row-cols-md-2 g-4 pt-4">
              {loading ? (
                <p>Loading...</p>
              ) : empdata.length > 0 ? (
                empdata.map((item) => (
                  <div className="col" key={item.project_id}>
                    <div className="card">
                      {item.image_url && (
                        <img
                          src={item.image_url[0].image_url}
                          alt={item.title}
                          className="p-3 pb-0"
                          height="400rem"
                        />
                      )}
                      <div className="card-body">
                        <h5 className="card-title">{item.title}</h5>
                        <p>Project URL : {item.project_url}</p>
                        <p>Project type : {item.project_type}</p>
                        <div className="d-flex mt-3">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-toggle="modal"
                            data-bs-target={`#exampleModal1-${item.project_id}`}
                          >
                            <i className="bi bi-bookmark-fill"></i>
                          </button>

                          <div
                            className="modal fade"
                            id={`exampleModal1-${item.project_id}`}
                            tabIndex="-1"
                            aria-labelledby={`exampleModalLabel-${item.project_id}`}
                            aria-hidden="true"
                          >
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h1
                                    className="modal-title fs-5"
                                    id={`exampleModalLabel-${item.project_id}`}
                                  >
                                    Detail Project
                                  </h1>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  <article
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <button
                            onClick={() => {
                              LoadEdit(item.project_id);
                            }}
                            className="btn btn-warning mx-2"
                          >
                            <i className="bi bi-pencil-square"></i>
                          </button>
                          <button
                            onClick={() => {
                              handleDelete(item.project_id);
                            }}
                            className="btn btn-danger"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No data available</p>
              )}
            </div>
          </div>
        </div>

        <Modal show={deleteItemId !== null} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Apakah Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={confirmDelete}>
              Ya, hapus
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Tidak
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default Projects;
