// api.js
const BASE_URL = "https://api-ekata.ekata.id/api";

export const getFooterData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/footer`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return Array.isArray(data) ? data : data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export const getCollaborateData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/collaborate`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return Array.isArray(data) ? data : data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export const getContactusData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/contactus`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return Array.isArray(data) ? data : data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export const getCompanyData = async () => {
  try {
    const response = await fetch(`${BASE_URL}/company`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();

    return Array.isArray(data) ? data : data.data || [];
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error;
  }
};

export const userApi = "https://api-ekata.ekata.id/api/user";

export const heroApi = "https://api-ekata.ekata.id/api/hero";

export const projectsApi =
  "https://api-ekata.ekata.id/api/projects";

export const statsApi = "https://api-ekata.ekata.id/api/stats";

export const partnersApi =
  "https://api-ekata.ekata.id/api/partners";

export const servicesApi =
  "https://api-ekata.ekata.id/api/services";

export const testimonialsApi =
  "https://api-ekata.ekata.id/api/testimonials";

export const footerApi = "https://api-ekata.ekata.id/api/footer";

export const contactusApi =
  "https://api-ekata.ekata.id/api/contactus";

export const companyApi =
  "https://api-ekata.ekata.id/api/company";

export const collaborateApi =
  "https://api-ekata.ekata.id/api/collaborate";
