// Edit_profile.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import authToken from "./../token/Token";
import { userApi } from "./../API/api";

const Edit_profile = ({ match }) => {
  const [userName, setUserName] = useState("");
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await axios.get(`${userApi}/${match.params.id}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const profileData = response.data.data;

        setUserName(profileData.name);
        setEmails(profileData.email);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchProfileData();
  }, [match.params.id]);

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/profile");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("name", userName);
    formData.append("email", emails);
    formData.append("_method", "PUT");

    try {
      await axios.post(`${userApi}/${match.params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="userName">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="emails">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_profile;
