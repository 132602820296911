// Login.js
import React, { useState } from "react";
import "./templates/Login.css";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async () => {
    if (email && password) {
      try {
        // Make the API request
        const response = await fetch(
          "https://api-ekata.ekata.id/api/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              password: password,
            }),
          }
        );

        if (response.ok) {
          onLogin();
        } else {
          alert("Login failed. Please check your credentials and try again.");
        }
      } catch (error) {
        console.error("Error during login:", error);
        alert("An error occurred during login. Please try again later.");
      }
    } else {
      alert("Please enter email and password.");
    }
  };

  return (
    <main>
      <section className="wrap-section-1 bglog">
        {/* Page content*/}
        <div className="container">
          <div className="mx-auto mt-lg-4 pagelog">
            <div className="row justify-content-center shadow p-3 mb-5 bg-white rounded">
              <div className="col-lg-12 text-center">
                <img src="assets\icons.svg" alt="logo" />
                <p>Admin Dashboard</p>
              </div>
              <div className="col-lg-12">
                <form>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control bg-light border-0"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control bg-light border-0"
                        id="password"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-light"
                        id="togglePassword"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {passwordVisible ? (
                          <i className="bi bi-eye"></i>
                        ) : (
                          <i className="bi bi-eye-slash"></i>
                        )}
                      </button>
                    </div>
                  </div>
                  <a
                    href="#!"
                    type="submit"
                    className="btn btn-warning buttonlog w-100"
                    onClick={handleLogin}
                  >
                    Login
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Login;
