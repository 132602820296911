// Dashboard.js
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import "./templates/Mediares.css";

import Profiles from "./imgs/profile.svg";
import Hello from "./imgs/hello.svg";
import SearchBar from "./templates/SearchBar";

// menubar
import Sidebar from "./templates/Sidebar";

// pages
import Hero from "./components/Hero";
import Projects from "./components/Projects";
import Stats from "./components/Stats";
import Partners from "./components/Partners";
import Services from "./components/Services";
import Testimonial from "./components/Testimonial";
import Footer from "./components/Footer";
import Profile from "./components/Profile";

// creates
import Create_projects from "./creates/Create_projects";
import Create_stats from "./creates/Create_stats";
import Create_partners from "./creates/Create_partners";
import Create_services from "./creates/Create_services";
import Create_testimonial from "./creates/Create_testimonial";

// updates
import Title_hero from "./hero/Title_hero";
import Content_hero from "./hero/Content_hero";
import Edit_projects from "./updates/Edit_projects";
import Edit_stats from "./updates/Edit_stats";
import Edit_partners from "./updates/Edit_partners";
import Edit_services from "./updates/Edit_services";
import Edit_testimonial from "./updates/Edit_testimonial";
import Edit_profile from "./updates/Edit_profile";

// footer
import Content from "./footer/Content";
import Contact from "./footer/Contact";
import Companys from "./footer/Companys";
import Collaborate from "./footer/Collaborate";

const Dashboard = ({ onLogout }) => {
  return (
    <Router>
      <div className="container-fluid">
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper" className="bg-light">
            {/* topbar */}
            <Nav className="navbar navbar-expand-lg bg-white">
              <div className="container-fluid px-5">
                <h6 id="sidebarToggle" type="button" onClick={onLogout}>
                  Hello, Admin <img src={Hello} alt="" width="15%" />
                </h6>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                    {/* responsive */}
                    <li className="nav-item pt-2 me-4 res">
                      <Nav className="flex-column">
                        <NavLink
                          exact
                          to="/components/hero"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Hero Section
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/projects"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Our Projects
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/stats"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Our Stats
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/partners"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Our Partners
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/services"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Our Services
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/testimonial"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Testimonial
                          </a>
                        </NavLink>
                        <NavLink
                          to="/components/footer"
                          className="nav-link"
                          activeClassName="active"
                        >
                          <a
                            className="nav-link list-group-item-action border-0 p-3 ps-4"
                            href="#!"
                          >
                            <i className="bi bi-grid me-3"></i> Footer Section
                          </a>
                        </NavLink>
                      </Nav>
                    </li>
                    {/* responsive */}

                    <li className="nav-item pt-2 me-4">
                      <SearchBar />
                    </li>
                    <li className="nav-item">
                      <a className="nav-link py-0" href="#!">
                        <Nav.Link as={Link} to="/components/profile">
                          <img src={Profiles} alt="" />
                        </Nav.Link>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Nav>
            {/* topbar */}

            <Switch>
              {/* pages */}
              <Route path="/components/hero" exact component={Hero} />
              <Route path="/components/projects" component={Projects} />
              <Route path="/components/stats" component={Stats} />
              <Route path="/components/partners" component={Partners} />
              <Route path="/components/services" component={Services} />
              <Route path="/components/testimonial" component={Testimonial} />
              <Route path="/components/footer" component={Footer} />
              <Route path="/components/profile" component={Profile} />

              {/* creates */}
              <Route
                path="/creates/create_projects"
                component={Create_projects}
              />
              <Route path="/creates/create_stats" component={Create_stats} />
              <Route
                path="/creates/create_partners"
                component={Create_partners}
              />
              <Route
                path="/creates/create_services"
                component={Create_services}
              />
              <Route
                path="/creates/create_testimonial"
                component={Create_testimonial}
              />

              {/* updates */}
              <Route path="/hero/title_hero/:id" component={Title_hero} />
              <Route path="/hero/content_hero/:id" component={Content_hero} />
              <Route
                path="/updates/edit_projects/:project_id"
                component={Edit_projects}
              />
              <Route
                path="/updates/edit_stats/:stat_id"
                component={Edit_stats}
              />
              <Route
                path="/updates/edit_partners/:partner_id"
                component={Edit_partners}
              />
              <Route
                path="/updates/edit_services/:service_id"
                component={Edit_services}
              />
              <Route
                path="/updates/edit_testimonial/:client_id"
                component={Edit_testimonial}
              />
              <Route
                path="/updates/edit_profile/:id"
                component={Edit_profile}
              />

              {/* footer */}
              <Route path="/footer/collaborate/:id" component={Collaborate} />
              <Route path="/footer/content/:id" component={Content} />
              <Route path="/footer/contact/:id" component={Contact} />
              <Route path="/footer/companys/:id" component={Companys} />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;
