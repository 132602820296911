// Edit_services.js
import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import authToken from "./../token/Token";
import { servicesApi } from "./../API/api";

const Edit_services = ({ match }) => {
  const [serviceName, setServiceName] = useState("");
  const [serviceUrl, setServiceUrl] = useState("");
  const [iconUrl, setIconUrl] = useState(null);
  const [initialIconUrl, setInitialIconUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        const response = await axios.get(
          `${servicesApi}/${match.params.service_id}`
        );

        const servicesData = response.data.data;

        setServiceName(servicesData.service_name);
        setServiceUrl(servicesData.service_url);
        setInitialIconUrl(servicesData.icon_url);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchServicesData();
  }, [match.params.service_id]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setIconUrl(file);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/services");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("service_name", serviceName);
    formData.append("service_url", serviceUrl);
    formData.append("_method", "PUT");

    if (iconUrl) {
      formData.append("icon_url", iconUrl);
    }

    try {
      await axios.post(`${servicesApi}/${match.params.service_id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="serviceName">
                  <Form.Label>Service Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={serviceName}
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="serviceUrl">
                  <Form.Label>Service URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={serviceUrl}
                    onChange={(e) => setServiceUrl(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="iconUrl">
                  <Form.Label>Edit Icon</Form.Label>
                  <Form.Control type="file" onChange={handleImageChange} />
                  <img
                    src={initialIconUrl}
                    alt="Initial Profile"
                    width="100"
                    className="my-3"
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Edit_services;
