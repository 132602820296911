import React from "react";
import { FaSearch } from "react-icons/fa";
import "./Search.css";

const SearchBar = () => {
  return (
    <div className="search-container">
      <input type="text" placeholder="" />
      <button className="search-button">
        <FaSearch />
      </button>
    </div>
  );
};

export default SearchBar;
