// App.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  return (
    <Router>
      <Switch>
        <Route path="/login">
          {isLoggedIn ? (
            <Redirect to="/dashboard" />
          ) : (
            <Login onLogin={handleLogin} />
          )}
        </Route>
        <Route path="/">
          {isLoggedIn ? (
            <Dashboard onLogout={handleLogout} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
