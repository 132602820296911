import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import authToken from "./../token/Token";
import { companyApi } from "./../API/api";

const Companys = ({ match }) => {
  const [titleName, setTitleName] = useState("");
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await axios.get(`${companyApi}/${match.params.id}`);

        const companyData = response.data.data;

        setTitleName(companyData.title);
        setContent(companyData.url);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchCompanyData();
  }, [match.params.id]);

  const handleCloseModal = () => {
    setShowModal(false);
    history.push("/components/footer");
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("title", titleName);
    formData.append("url", content);
    formData.append("_method", "PUT");

    try {
      await axios.post(`${companyApi}/${match.params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section>
      <div className="container wrap-section-1">
        <div className="row mx-3">
          <div className="col-lg">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Form>
                <Form.Group className="mb-3" controlId="TitleName">
                  <Form.Label>Edit title</Form.Label>
                  <Form.Control
                    type="text"
                    value={titleName}
                    onChange={(e) => setTitleName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="Content">
                  <Form.Label>Edit URL</Form.Label>
                  <Form.Control
                    type="text"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />
                </Form.Group>

                <Button variant="warning" onClick={handleSubmit}>
                  Simpan
                </Button>

                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Data Success Update</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Your data has been successfully updated!
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="warning" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Form>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Companys;
